<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <!-- 基本信息 -->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">事故信息</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-input v-model="form.careUnitName" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                  disabled
                ></el-cascader>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="采购时间" prop="purchaseDate">
                <el-date-picker
                  v-model="form.purchaseDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                  disabled
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="物资类型" prop="materialType">
                <el-input v-model="form.materialType" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="采购金额(元)" prop="purchaseAmount">
                <el-input v-model="form.purchaseAmount" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="采购数量(个)" prop="purchaseCount">
                <el-input v-model="form.purchaseCount" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="在用数量(个)" prop="useCount">
                <el-input v-model="form.useCount" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="库存数量(个)" prop="inventoryCount">
                <el-input v-model="form.inventoryCount" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="物资管理人" prop="materialName">
                <el-input v-model="form.materialName" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="联系方式" prop="materialPhone">
                <el-input v-model="form.materialPhone" placeholder="请输入" maxlength="11" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="物资照片">
                <ayl-img-upload
                  v-model="materialUrls"
                  :imgListLength="50"
                  :imgWidth="'130px'"
                  :imgHeight="'130px'"
                  :isEdit="false"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button v-if="0" type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      debug: 0,
      nav: [{ name: "安全物资管理详情" }],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        purchaseDate: null, //采购时间
        materialType: null, //物资类型
        purchaseAmount: null, //采购金额
        purchaseCount: null, //采购数量
        useCount: null, //在用数量
        inventoryCount: null, //库存数量
        materialName: null, //物资管理人
        materialPhone: null, //联系方式
        materialUrls: null //物资图片
      },
      materialUrls: [],
      bidList: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请输入养护企业",
          trigger: "blur"
        },
        bidId: {
          required: true,
          message: "请选择标段名称",
          trigger: "change"
        },
        purchaseDate: {
          required: true,
          message: "请选择采购时间",
          trigger: "change"
        },

        materialType: {
          required: true,
          message: "请输入物资类型",
          trigger: "blur"
        },
        purchaseAmount: {
          required: true,
          message: "请输入采购金额",
          trigger: "blur"
        },
        purchaseCount: {
          required: true,
          message: "请输入采购数量",
          trigger: "blur"
        },
        useCount: {
          required: true,
          message: "请输入在用数量",
          trigger: "blur"
        },
        inventoryCount: {
          required: true,
          message: "请输入库存数量",
          trigger: "blur"
        }
      }
    };
  },

  methods: {
    hanldBidId(arr) {
      this.form.bidId = arr[1];
    },
    //关闭
    onClose() {
      BUS.$emit(BUSEVENT.REFRESH_SAFETY_SUPPLIES_MANAGEMENT);
      this.$emit("onClose"); //关闭弹窗
      this.$refs["form"].resetFields();
    },

    async submit() {
      await this.log(this.form);
      let params = JSON.parse(JSON.stringify(this.form));
      params.purchaseDate = this.form.purchaseDate + "";
      params.materialUrls = this.materialUrls.join(",");
      await this.$api_hw
        .operationSafetyManagement_updateMaterialInfo({
          sanitationMaterialManageVO: params
        })
        .then(res => {
          this.$notify({
            title: "成功",
            message: `${this.nav[0].name}成功`,
            type: "success"
          });

          this.onClose();
        });
      this.log(params);
    },

    // 提交表单
    onSubmit() {
      if (this.submitLoading) return; //防止重复提交
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          this.submit();
        } catch (e) {
          console.log(e);
        } finally {
          this.submitLoading = false;
        }
      });
    }
  },
  async mounted() {
    this.$refs["form"].resetFields();
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    //获取详情数据
    this.log(this.dataFromDialog);
    this.form = await this.$api_hw.operationSafetyManagement_getMaterialInfo({
      id: this.dataFromDialog.materialId
    });
    this.materialUrls = this.form.materialUrls
      ? this.form.materialUrls.split(",")
      : [];
  }
};
</script>

<style lang='sass' scoped>
.content-box 
  padding-top: 20px

.form-label-base
  border-left: 2px solid $-color-primary-3
  padding-left: 8px
/deep/ .el-button--primary
  background-color: $-color-primary-3
  border-color: $-color-primary-3

/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner
  color: #526a75
</style>